
.cardopper{
    display: flex;
padding: 16px;
flex-direction: column;
align-items: flex-end;
gap: 56px;
border-radius: 8px;
background: #0A033C;    
color:"#ffff";
}
.error-message {
  position: absolute;
  bottom: -20px; 
  left: 0;
  width: 100%;
  font-size: 12px;
  color: red;
}
.privacy-policy {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;

  margin-bottom: 50px;
  background-color: #f7f7f7;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}
.card-container12 {
  display: grid;
  grid-template-columns: repeat(3, 1fr); 
  gap: 30px; 
  margin-bottom: 50px;
}

.privacy-policy-title {
  font-size: 24px;
  margin-bottom: 20px;
}

.privacy-policy-section {
  margin-bottom: 20px;
}

h2 {
  font-size: 20px;
  margin-bottom: 10px;
}

p {
  font-size: 16px;
}

.coursename {
  color: var(--ffffff, #FFF);
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: capitalize;
  
  width: 200px; 
  height: 30px; 
  
  margin-right: 100px;
 
}
.nevertext
.collapsedContent {
  height: "calc(20vh - 200px)";
  overflow-y: "auto";

}


.teachername {
  color: var(--ffffff, #FFF);
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: capitalize;
  
  width: 100%; 
  height: 20%;
}

.grade{
  display: flex;
  gap: 68px;
  border-radius: 8px;
  background: var(--ffffff, #FFF);
  font-size: 18px;
  width: 100%;
  margin-top: -20px;

}
.little {
  color: var(--foundation-red-normal, #FF6652);
 
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: capitalize;
    
  }
  .abouttext{
    color: #000;
font-family: Poppins;
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: normal;
  }
  .about {
    color: "#0A033C";
   
    font-family: Poppins;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-transform: capitalize;
      
    }
  
  .offer {
    color: "#0A033C";
   
    font-family: Poppins;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-transform: capitalize;
    padding-top: 40px;
      
    }
  .cardss {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
  }
  .sectionno{
    display: flex;
    flex-direction: row;
    
  }
  .section{
    color: var(--000000, #000);

font-family: Poppins;
font-size: 12px;
font-style: normal;
font-weight: 400;
line-height: normal;
  }
  .coursedetailbtn{
    display: flex;
padding: 14px 24px;
justify-content: center;
align-items: center;
gap: 10px;
flex: 1 0 0;
border-radius: 8px;
border: 1.5px solid var(--foundation-red-normal, #FF6652);
background: var(--ffffff, #FFF);
box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.14);
color: var(--foundation-red-normal, #FF6652);
font-family: Poppins;
font-size: 12px;
font-style: normal;
font-weight: 600;
line-height: normal;
flex: 1;
 margin-Right: 8px
  }
  .enrollbtn{
    display: flex;
padding: 14px 24px;
justify-content: center;
color: var(--ffffff, #FFF);
font-family: Poppins;
font-size: 12px;
font-style: normal;
font-weight: 600;
line-height: normal;
align-items: center;
flex: 1; margin-Left: 8px;
gap: 10px;
flex: 1 0 0;
border-radius: 8px;
background: var(--foundation-purple-normal, #0A033C);
box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.14);
color:white
  }
  
  .card-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr); 
    gap: 20px; 
  }
  
  @media (max-width: 768px) {
   
    .card-grid {
      grid-template-columns: 1fr;
    }
  }
  
 
  
  .boldtext {
    color: var(--000000, #000);
    font-family: Poppins;
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-transform: capitalize;
    margin-top: 2px;
  }
  
  .text {
    color: var(--000000, #000);
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    text-transform: capitalize;
    margin-top: -30px;
  }
  .footerr{
   padding-left: 120px;
   padding-right: 120px;
   
  }
  
  .firstgrid {
    margin-left: 120px;
    margin-top: 120px;
    margin-bottom: 80px;
  }
  .firstabout {
    margin-left: 120px;
    margin-top: 0px;
    margin-bottom: 80px;
  }
  .numbers {
    margin-bottom: 20px;
    text-decoration: none;
  }
  
  .numbe {
    color: var(--000000, #000);
    font-family: Poppins;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-decoration: none;
  }
  
  .number {
    margin-left: 74px;
    color: var(--000000, #000);
    font-family: Poppins;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-decoration: none;
  }
  
  .grad {
    color: var(--000000, #000);
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    margin-left: -200px;
    margin-top: -40px ;
  
   
  }
  .gradenumber{
    display: flex;
padding: 10px 12px;
justify-content: center;
align-items: center;
gap: 10px;
border-radius: 8px;
background: var(--ffffff, #FFF);
  }
  
  .grades {
    margin-left: 68px;
    color: var(--000000, #000);
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-top: -40px ;
   
    
  }
  
  
  .courses{
    color: var(--foundation-red-normal, #FF6652);
font-family: Poppins;
font-size: 16px;
font-style: normal;
font-weight: 500;
line-height: normal;
text-transform: lowercase;
  }
  .qualified{
    color: var(--000000, #000);

font-family: Poppins;
font-size: 20px;
font-style: normal;
font-weight: 700;
line-height: normal;
text-transform: capitalize;
  }
  .text2{
    color: var(--000000, #000);
font-family: Poppins;
font-size: 14px;
font-style: normal;
font-weight: 300;
line-height: normal;
text-transform: capitalize;
  }
  .secondgrid{
    padding: 120px;
    padding-right: 120px;
    padding-top: 80px;
    padding-bottom: 80px;
    background-color: #fbf1ea;
 
  
    
  }
  /* Default styles for larger screens */
.thrdgrid {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #fbf1ea;
 
}

.thrdgrid img {
  max-width: 100%;
  height: auto;
}

/* Media query for tablets */
@media (max-width: 768px) {
  .thrdgrid {
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
    
  }
  .firstabout {
    margin-left: 40px;
    margin-top: 0px;
    margin-bottom: 40px;
  }
  .footerr{
    
    padding-right: 120px;
    padding-left: 120px;
  }
  
  .thrdgrid img {
    margin-top: 20px; /* Add some spacing between the text and image */
  }
}

  .secondlayout{
    display: flex;
width: 196px;
height: 744px;
padding: 24px 24px 24px 0px;
flex-direction: column;
align-items: flex-end;
border-radius: 8px;
background-color:  #FF6652;
  }
  .firstlogin{
gap: 40px;
align-self: stretch;
margin-top: 100px;
margin-bottom: 100px;
margin-left: auto;
margin-right: auto;
    padding: 2rem;
    height: 100%;
    background-color: #fbf1ea; 
    
  
  }
  .firstsignup{
    gap: 40px;
    align-self: stretch;
    margin-left: auto;
    margin-right: auto;
        padding: 2rem;
        height: 100%;
        background-color: #fbf1ea; 
        
      
      }
  .custom-font {
    font-family: 'Poppins', Arial, sans-serif;
  }
  .textFieldCustomWidth {
    width: 80%; /* Adjust this value as needed */
  }

  .video{

   margin-bottom: 80px;
   width: 1000px;
   
 
   
  }
  .fifthgrade{
    background-color: #FFFF;
   padding-left: 120px;
   padding-right: 120px;
   padding-top: 80px;
    padding-bottom: 80px;

  }
  /* Styles for mobile view */
@media (max-width: 600px) {
  .fifthgrade {
    flex-direction: column;
   width: 100%;
   padding-left: 20px;
  }
  .footerr{
    
    padding-right: 0px;
    padding-left: 0px;
  }
  .mobileGridContainer {
    margin-top: 15px; /* Add some spacing between the grids in mobile view */
  }

  /* Add any other necessary styles for mobile view */
}

  .sameitem{
    color: var(--000000, #000);
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    font-family: Poppins;
    text-transform: capitalize;
    display: flex;
    width: 282px;
    padding: 20px;
    align-items: center;
    gap: 12px;
    flex-shrink: 0;
    border-radius: 8px;
background: var(--ffffff, #FFF);
border-radius: 8px;
background: var(--ffffff, #FFF);
box-shadow: -2px 4px 4px 0px rgba(0, 0, 0, 0.14), 2px 0px 4px 0px rgba(0, 0, 0, 0.14);
  }
  .sixgrade{
    padding-left: 120px;
    
    padding-right: 120px;
  }
  .enrollfgrid{
    background-color: #FFF;
    margin-top: 40px;
    margin-bottom: 40px;
    margin-right: 60px;
    margin-left: 60px;
  }
  .subenrollfgrid{
    margin-top: 10px;
    margin-bottom: 10px;
    margin-right: 20px;
    margin-left: 20px;
    
  }
  .Modal{
   
    
  }
  .strength{
    color: #FF6652;
  }
  /* Loading.css */

.loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh; /* Makes the container fill the viewport vertically */
}

.loading-image {
  width: 80px; /* Adjust the size as needed */
  height: auto; /* Maintain the aspect ratio */
}

  .text{
    color: var(--000000, #000);
font-family: Poppins;
font-size: 14px;
font-style: normal;
font-weight: 300;
line-height: normal;
text-transform: capitalize;
  }
  .fourthgrid {
    display: grid;
    grid-template-columns: 1fr;
    gap: 10px;
   margin-left: 120px;
   margin-right: 120px;
  }
  .secabout{
    display: grid;
    grid-template-columns: 1fr;
    gap: 10px;
   padding-left: 120px;
   padding-right: 120px;
   padding-bottom: 30px;
   background-color: #fbf1ea;
  }
  .image-container {
    display: flex;
    flex-direction: column;
    padding-bottom: 20px;
  }

  .responsive-image {
    max-width: 100%;
    height: auto;
    margin-bottom: 10px; 
  }

  @media (min-width: 768px) {
    .image-container {
      flex-direction: row;
      justify-content: space-between;
      align-items: center; 
    }
    .responsive-image {
      max-width: calc(20% - 10px); 
      margin-bottom: 0; 
    }
  }
  .strength {
    font-size: 16px;
    
  }
  
  .text2 {
    font-size: 18px;
    
  }
  
  .video-container {
    position: relative;
    overflow: hidden;
    width: 100%;
    padding-top: 56.25%; /* 16:9 aspect ratio */
  }
  
  .video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  
  /* Media Queries for Tablet and Mobile */
  @media (max-width: 768px) {
    .fourthgrid {
      grid-template-columns: 1fr;
      padding: 10px;
      width: 100%;
      margin-left: 0px;
      margin-right: 0px;
    }
    .secabout{
      display: grid;
      grid-template-columns: 1fr;
      gap: 10px;
     padding-left: 40px;
     padding-right: 20px;
     padding-bottom: 10px;
     background-color: #fbf1ea;
    }
    
    .offer {
      color: "#0A033C";
     
      font-family: Poppins;
      font-size: 20px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      text-transform: capitalize;
      padding-top: 10px;
        
      }
    .strength {
      font-size: 14px;
    }
  
    .text2 {
      font-size: 16px;
    }
  }
  
  .griditem{
   
 }
 .textfooter{
color: #000;
font-family: Poppins;
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: normal;
text-transform: lowercase;
align-self: stretch;
width: 70%;
 }
 .samemail{
color: #000;
font-family: Poppins;
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: normal;
text-transform: lowercase;

 }
 .subgrid{
align-items: flex-start;
gap: 32px;
color: #000;
font-family: Poppins;
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: normal;
text-transform: capitalize;
 }
 .textfield{
    display: flex;
padding: 14px 24px;
align-items: center;
gap: 10px;
align-self: stretch;
border-radius: 8px;
background: var(--ffffff, #FFF);
box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.14);
 }
 .card-container {
  display: flex;
  flex-direction: column;
  gap: 20px; /* Adjust as needed */

  @media (min-width: 768px) {
    flex-direction: row;
    justify-content: space-between;
    align-items: stretch;
  }

  /* Add more media queries for larger screens if needed */
}

.simple-card {
  width: 100%; /* Full width for all screen sizes */

  @media (min-width: 768px) {
    width: calc(33.33% - 20px); /* Three cards per row on tablets */
  }

  /* Add more media queries for larger screens if needed */

  /* ... Other card styling ... */
}

  /* Media Query for 1440x669 screen size */
  @media (max-width: 1440px) and (max-height: 669px) {
    .firstgrid {
      margin-left: 60px;
      margin-top: 80px;
     
    }
    
    .boldtext {
      font-size: 30px;
    }
  
    .text {
      font-size: 14px;
    }
  
    .grad {
      color: var(--000000, #000);
      font-family: Poppins;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      margin-left: -200px;
      margin-top: -40px ;
     
    }
    
    .mainnumber {
      margin-top: 590px;
      
     
    }
  }
  
  /* Media Query for 900px screen size (Tablet) */
  @media (max-width: 1000px) {
    .firstgrid {
      margin-left: 50px;
      margin-top: 70px;
    }
    .grade{
      display: flex;
      gap: 68px;
      border-radius: 8px;
      background: var(--ffffff, #FFF);
    
      width: 100%;
      margin-top: -20px;
    }
    .Modal{
     
  
    }
  
    .boldtext {
      font-size: 30px;
    }
  
    .text {
      font-size: 14px;
    }
    .secondgrid {
        margin-left: 40px;
        margin-right: 40px;
        margin-top: 60px;
      }
    .numbe,
    .number {
      font-size: 20px;
    }
  
    
  
    .mainnumber {
      margin-top: 50px;
    }
  }
  .loginsecgrid{
   
   
     }
     .search-bar {
        display: flex;
        width: 384px;
        height: 42px;
        padding: 14px 24px;
        align-items: center;
        gap: 12px;
        border: 1px solid #ccc;
        border-radius: 4px;
      }
      .searchbox{
        border:   "1px red solid";
      }
      .search-bar img {
        width: 24px; /* Adjust the width of the search icon as needed */
        height: 24px; /* Adjust the height of the search icon as needed */
      }
      
      /* If you want to style the text input */
      #outlined-basic {
        flex: 1; /* This will make the input take up the remaining space */
        border: none;
        outline: none;
        font-size: 16px; /* Adjust the font size as needed */
      }
          
  /* Media Query for 500px screen size (Mobile) */
  @media (max-width: 600px) {
    .loginsecgrid{
   display: none;
    }
    .Modal{
     
      width: 95%;
    }
    /* .firstlogin{
        
        gap: 40px;
        align-self: stretch;
        margin-top: 100px;
        margin-bottom: 100px;
        margin-left: auto;
        margin-right: auto;
            padding: 2rem;
            height: 100%;
            background-color: #fbf1ea; 
            
          
          } */
    .firstgrid {
      margin-left: 30px;
      margin-top: -30px;
    }
    .secondgrid {
        margin-left: 20px;
        margin-right: 20px;
        margin-top: 40px;
      }
  
    .boldtext {
      font-size: 24px;
    }
  
    .text {
      font-size: 12px;
    }
  
    .numbe,
    .number {
      font-size: 18px;
    }
  
    
  
    .mainnumber {
      margin-top: 30px;
      
    }
  }
  